import "./Drop.css"
import sliderImage from "../IMages/slider.png";
import collectionDefault from "../IMages/collection-deafult.png"

function Drops() {

    return (
        <div className={'container drop-wrapper'}>
            <div className={'row'}>
                <h2 className={'title'}> Drops </h2>
                <div className={'minting-wrapper'}>
                    <div className={'slider'}>
                        <ul>
                            <li style={{backgroundImage: `url( ${sliderImage} )`}}>
                                <div>
                                    <div className={``}>
                                        <span className={`minting-now`}> Minting now </span>
                                    </div>
                                    <div className={``}>
                                        <div className={`collection-wrap`}>
                                            <h3 className={`title`}> NameCrypto </h3>
                                            <div className={`collection-details`}>
                                                <div className={``}>
                                                    <span> by Creator </span>
                                                    <span className={`price-details`}> 10,000 items - <svg
                                                        xmlns="http://www.w3.org/2000/svg" width="10"
                                                        height="16" viewBox="0 0 10 16" fill="none"><g
                                                        clip-path="url(#clip0_453_11454)"><path
                                                        d="M9.1224 7.93913L4.67967 10.7229L0.234009 7.93913L4.67967 0.115723L9.1224 7.93913ZM4.67967 11.6168L0.234009 8.83306L4.67967 15.4746L9.12532 8.83306L4.67967 11.6168Z"
                                                        fill="white"/></g><defs><clipPath id="clip0_453_11454"><rect
                                                        width="9.35928" height="15.3588" fill="white"
                                                        transform="translate(0 0.115601)"/></clipPath></defs></svg> 0.25 ETH </span>
                                                </div>
                                                <span className={`timer`}> Starts in  3h 50m 2s </span>
                                            </div>
                                        </div>
                                        <div className={``}>
                                            <button> View drop</button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className={'google-ad'}>
                        Google Ad
                    </div>
                </div>
                <div className={`active-minting-wrapper`}>
                    <div className={``}>
                        <h2 className={`title`}> Active <span className={`minting-now`}> Minting now </span></h2>
                        <div className={`collections-wrap`}>
                            <div className={`collection-wrap`}>
                                <img src={collectionDefault} alt={``}/>
                                <div className={`collection-details`}>
                                    <div className={`collection-name`}>
                                        <span className={`name`}> Drop name </span>
                                        <span className={`creator-wrap`}> by Creator name here </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             viewBox="0 0 16 16" fill="none">
                                            <path
                                                d="M15.1707 7.05395L14.1507 5.86895C13.9557 5.64395 13.7982 5.22395 13.7982 4.92395V3.64895C13.7982 2.85395 13.1457 2.20144 12.3507 2.20144H11.0757C10.7832 2.20144 10.3557 2.04395 10.1307 1.84895L8.94567 0.828945C8.42817 0.386445 7.58067 0.386445 7.05567 0.828945L5.87814 1.85645C5.65314 2.04395 5.22564 2.20144 4.93314 2.20144H3.63564C2.84064 2.20144 2.18814 2.85395 2.18814 3.64895V4.93145C2.18814 5.22395 2.03064 5.64395 1.84314 5.86895L0.830644 7.06145C0.395645 7.57895 0.395645 8.41895 0.830644 8.93645L1.84314 10.129C2.03064 10.354 2.18814 10.774 2.18814 11.0665V12.349C2.18814 13.144 2.84064 13.7965 3.63564 13.7965H4.93314C5.22564 13.7965 5.65314 13.954 5.87814 14.149L7.06317 15.169C7.58067 15.6115 8.42817 15.6115 8.95317 15.169L10.1382 14.149C10.3632 13.954 10.7832 13.7965 11.0832 13.7965H12.3582C13.1532 13.7965 13.8057 13.144 13.8057 12.349V11.074C13.8057 10.7815 13.9632 10.354 14.1582 10.129L15.1782 8.94395C15.6132 8.42645 15.6132 7.57145 15.1707 7.05395ZM11.1207 6.58145L7.49817 10.204C7.39317 10.309 7.25067 10.369 7.10067 10.369C6.95067 10.369 6.80817 10.309 6.70317 10.204L4.88814 8.38895C4.67064 8.17145 4.67064 7.81145 4.88814 7.59395C5.10564 7.37645 5.46564 7.37645 5.68314 7.59395L7.10067 9.01145L10.3257 5.78645C10.5432 5.56894 10.9032 5.56894 11.1207 5.78645C11.3382 6.00394 11.3382 6.36394 11.1207 6.58145Z"
                                                fill="#1E93FF"/>
                                        </svg>
                                    </div>
                                    <div className={`collection-minting-wrapper`}>
                                        <span> Minting now on Foundation.app </span>
                                        <span className={`price`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22" viewBox="0 0 13 22" fill="none">
                                                <g clip-path="url(#clip0_453_11496)">
                                                    <path d="M12.671 10.4564L6.50007 14.3231L0.325073 10.4564L6.50007 -0.410278L12.671 10.4564ZM6.50007 15.5647L0.325073 11.6981L6.50007 20.9231L12.6751 11.6981L6.50007 15.5647Z" fill="#3A3A3A"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_453_11496">
                                                        <rect width="13" height="21.3333" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            0.0025 ETH
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={`collections-list-wrap`}>
                                <div className={`collection-wrap`}>
                                    <img src={collectionDefault} alt={``}/>
                                    <div className={`collection-details`}>
                                        <div className={`collection-name`}>
                                            <span className={`name`}> Drop name </span>
                                            <span className={`creator-wrap`}> by Creator name here </span>
                                        </div>
                                        <div className={`collection-minting-wrapper`}>
                                            <span> Minting now </span>
                                            <span className={`price`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22"
                                                 viewBox="0 0 13 22" fill="none">
                                                <g clip-path="url(#clip0_453_11496)">
                                                    <path
                                                        d="M12.671 10.4564L6.50007 14.3231L0.325073 10.4564L6.50007 -0.410278L12.671 10.4564ZM6.50007 15.5647L0.325073 11.6981L6.50007 20.9231L12.6751 11.6981L6.50007 15.5647Z"
                                                        fill="#3A3A3A"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_453_11496">
                                                        <rect width="13" height="21.3333" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            0.0025 ETH
                                        </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`collection-wrap`}>
                                    <img src={collectionDefault} alt={``}/>
                                    <div className={`collection-details`}>
                                        <div className={`collection-name`}>
                                            <span className={`name`}> Drop name </span>
                                            <span className={`creator-wrap`}> by Creator name here </span>
                                        </div>
                                        <div className={`collection-minting-wrapper`}>
                                            <span> Minting now </span>
                                            <span className={`price`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22"
                                                 viewBox="0 0 13 22" fill="none">
                                                <g clip-path="url(#clip0_453_11496)">
                                                    <path
                                                        d="M12.671 10.4564L6.50007 14.3231L0.325073 10.4564L6.50007 -0.410278L12.671 10.4564ZM6.50007 15.5647L0.325073 11.6981L6.50007 20.9231L12.6751 11.6981L6.50007 15.5647Z"
                                                        fill="#3A3A3A"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_453_11496">
                                                        <rect width="13" height="21.3333" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            0.0025 ETH
                                        </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={`collection-wrap`}>
                                    <img src={collectionDefault} alt={``}/>
                                    <div className={`collection-details`}>
                                        <div className={`collection-name`}>
                                            <span className={`name`}> Drop name </span>
                                            <span className={`creator-wrap`}> by Creator name here </span>
                                        </div>
                                        <div className={`collection-minting-wrapper`}>
                                            <span> Minting now </span>
                                            <span className={`price`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22"
                                                 viewBox="0 0 13 22" fill="none">
                                                <g clip-path="url(#clip0_453_11496)">
                                                    <path
                                                        d="M12.671 10.4564L6.50007 14.3231L0.325073 10.4564L6.50007 -0.410278L12.671 10.4564ZM6.50007 15.5647L0.325073 11.6981L6.50007 20.9231L12.6751 11.6981L6.50007 15.5647Z"
                                                        fill="#3A3A3A"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_453_11496">
                                                        <rect width="13" height="21.3333" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            0.0025 ETH
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={``}>
                        <h2 className={`title`}> Upcoming </h2>
                        <div className={`collections-list-wrap`}>
                            <div className={`collection-wrap`}>
                                <img src={collectionDefault} alt={``}/>
                                <div className={`collection-details`}>
                                    <div className={`collection-name`}>
                                        <span className={`name`}> Drop name </span>
                                        <span className={`creator-wrap`}> by Creator name here </span>
                                    </div>
                                    <div className={`collection-minting-wrapper`}>
                                        <span> Minting now </span>
                                        <span className={`price`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22"
                                                 viewBox="0 0 13 22" fill="none">
                                                <g clip-path="url(#clip0_453_11496)">
                                                    <path
                                                        d="M12.671 10.4564L6.50007 14.3231L0.325073 10.4564L6.50007 -0.410278L12.671 10.4564ZM6.50007 15.5647L0.325073 11.6981L6.50007 20.9231L12.6751 11.6981L6.50007 15.5647Z"
                                                        fill="#3A3A3A"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_453_11496">
                                                        <rect width="13" height="21.3333" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            0.0025 ETH
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={`collection-wrap`}>
                                <img src={collectionDefault} alt={``}/>
                                <div className={`collection-details`}>
                                    <div className={`collection-name`}>
                                        <span className={`name`}> Drop name </span>
                                        <span className={`creator-wrap`}> by Creator name here </span>
                                    </div>
                                    <div className={`collection-minting-wrapper`}>
                                        <span> Minting now </span>
                                        <span className={`price`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22"
                                                 viewBox="0 0 13 22" fill="none">
                                                <g clip-path="url(#clip0_453_11496)">
                                                    <path
                                                        d="M12.671 10.4564L6.50007 14.3231L0.325073 10.4564L6.50007 -0.410278L12.671 10.4564ZM6.50007 15.5647L0.325073 11.6981L6.50007 20.9231L12.6751 11.6981L6.50007 15.5647Z"
                                                        fill="#3A3A3A"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_453_11496">
                                                        <rect width="13" height="21.3333" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            0.0025 ETH
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className={`collection-wrap`}>
                                <img src={collectionDefault} alt={``}/>
                                <div className={`collection-details`}>
                                    <div className={`collection-name`}>
                                        <span className={`name`}> Drop name </span>
                                        <span className={`creator-wrap`}> by Creator name here </span>
                                    </div>
                                    <div className={`collection-minting-wrapper`}>
                                        <span> Minting now </span>
                                        <span className={`price`}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="22"
                                                 viewBox="0 0 13 22" fill="none">
                                                <g clip-path="url(#clip0_453_11496)">
                                                    <path
                                                        d="M12.671 10.4564L6.50007 14.3231L0.325073 10.4564L6.50007 -0.410278L12.671 10.4564ZM6.50007 15.5647L0.325073 11.6981L6.50007 20.9231L12.6751 11.6981L6.50007 15.5647Z"
                                                        fill="#3A3A3A"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_453_11496">
                                                        <rect width="13" height="21.3333" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            0.0025 ETH
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Drops;