import Image from "../../IMages/image 1.png";
import React from "react";
import openSea from "../../IMages/Ellipse 95.svg";

function NFTs ({ collections, nftList }) {

    return (
        <>
            <div className="nft-grid">

                {
                    collections.map((collection) => (
                        <div className="nft-card" key={collection._id}>
                            <div style={{ position: 'relative' }}>
                                <img src={collection.image ? collection.image : Image} alt={collection.name}
                                     className="nft-image"/>
                                <img src={openSea} alt={collection.name}
                                     className="nft-image"
                                     style={{
                                         position: 'absolute',
                                         width: '30px',
                                         height: '30px',
                                         left: '15px',
                                         bottom: '-8px',
                                     }}
                                />
                            </div>
                            <div className="nft-details">
                                <h3>{collection.name}</h3>
                                {/*<p>No anme</p>*/}
                                <div className="nft-meta">
                                    <span
                                        style={{
                                            display: 'flex',
                                            gap: '5px',
                                            color: '#00AC4F',
                                            fontWeight: 700,
                                            alignItems: 'center'
                                        }}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16"
                                             viewBox="0 0 10 16" fill="none">
                                            <path
                                                d="M9.24728 8.1558L4.80454 10.9396L0.358887 8.1558L4.80454 0.332397L9.24728 8.1558ZM4.80454 11.8335L0.358887 9.04974L4.80454 15.6912L9.2502 9.04974L4.80454 11.8335V11.8335Z"
                                                fill="#00AC4F"/>
                                        </svg>
                                        {collection.volume} ETH</span>
                                    <span> 1 of 321 </span>
                                </div>
                                <div className="nft-details-bottom">
                                    <span
                                        className={ 'time' }
                                    > 3<small>h</small> 50<small>m</small> 2<small>s</small> <small>left</small> </span>
                                    <span className={ 'link' }> View details </span>
                                </div>
                            </div>
                        </div>
                    ))
                }

                {/*{nftList.map((nft) => (
                    <div className="nft-card" key={nft.id}>
                        <img src={nft.image} alt={nft.name} className="nft-image"/>
                        <div className="nft-details">
                            <h3>{nft.name}</h3>
                            <p>{nft.creator}</p>
                            <div className="nft-meta">
                                <span>{nft.price}</span>
                                <span>{nft.timeLeft} left</span>
                            </div>
                            <button className="view-details">View Details</button>
                        </div>
                    </div>
                ))}*/}
            </div>

            <div className="load-more">
                <button>Load More</button>
            </div>
        </>
    );

}

export default NFTs;