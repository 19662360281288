// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import usersReducer from '../features/users/usersSlice';
import tabsReducer from '../features/tabsSlice';
import { api } from '../services/api';

export const store = configureStore({
    reducer: {
        users: usersReducer,
        tabs: tabsReducer,
        [api.reducerPath]: api.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(api.middleware),
});
