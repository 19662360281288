import { Outlet, Link } from "react-router-dom";

function LayoutMain () {

    return (
        <>
        <Outlet />
        </>
    );

}

export default LayoutMain ;