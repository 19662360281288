import "@fontsource/dm-sans"; // Defaults to weight 400
import "@fontsource/dm-sans/400.css"; // Specify weight
import "@fontsource/dm-sans/400-italic.css"; // Specify weight and style

import {BrowserRouter, BrowserRouter as Router, Route, Routes} from "react-router-dom";

import './App.css';
import LandingPage from "./pages/LandingPage";
import SearchPage from "./pages/SearchPage";
import LayoutMain from "./Layout";

function App() {
  return (
      <div className="App">
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<LayoutMain />}>
                      <Route index element={<LandingPage />} />
                      <Route path="/search/:type/" element={<SearchPage />} />
                      <Route path="/search/:type/:keyword" element={<SearchPage />} />
                      <Route path="/site/:site" element={<SearchPage />} />
                      <Route path="*" element={<></>} />
                  </Route>
              </Routes>
          </BrowserRouter>
      </div>
  );
}

export default App;
