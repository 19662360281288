import React from 'react';
import './Hero.css';
import Image from '../IMages/Group 114.png'
import {useNavigate} from "react-router-dom";

const Hero = () => {

    const navigate = useNavigate();

    const [ search, setSearch ] = React.useState('');

    return (
        <div className={'container'} style={{backgroundColor: '#fff'}}>
            <div className={'row'}>
                <section className="hero">
                    <div className="hero-content">
                        <h1>The Worlds Largest NFT Search Engine</h1>
                        <p>NFT Galore allows you to search and explore NFTs from major marketplaces in one place.</p>
                        <div className="search-bar">
                            <input value={search} type="text" placeholder="Search for collections, name, or trait" onChange={ ( input ) => setSearch( input.target.value ) } />
                            <button onClick={ () => navigate( `/search/${search}` ) }>/</button>
                        </div>
                    </div>
                    <div className="hero-card">
                        <img src={Image} alt="Featured NFT"/>

                    </div>
                </section>
            </div>
        </div>
    );
    
};

export default Hero;
