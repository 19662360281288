import React from 'react';
import './Discover.css';
import Image from '../IMages/image 1.png'
import {useGetCollectionsQuery} from "../services/api";
import NFTs from "./NFTs";
import Collections from "./Collections";
import Filters from "./Filters";
import {useLocation, useParams} from "react-router-dom";

const Discover = () => {

    const location = useLocation();
    const isSearchPath = location.pathname.includes('search');
    const { site, type, keyword } = useParams();
    const { data: collections, isLoading, isError } = useGetCollectionsQuery( { keyword: type } );

    console.log( type )

    // Sample data to represent NFTs
    const nftList = [
        {
            id: 1,
            name: 'ArtCrypto',
            price: '0.25 ETH',
            image: Image,
            creator: 'By Creator Name',
            timeLeft: '3h 20m',
        },
        {
            id: 2,
            name: 'This NFT',
            price: '0.35 ETH',
            image: Image,
            creator: 'By Creator Name',
            timeLeft: '5h 15m',
        },
        // Add more NFT items here as needed
    ];

    if ( isLoading ) {

        return (
            <> Loading... </>
        )

    }

    return (
        <div className={'container'} style={{backgroundColor: '#D9E0EC33'}}>
            <div className={'row'}>
                <section className="discover-section">
                    <div className="discover-header">
                        {
                            !isSearchPath ?
                                <><h2>Discover More NFTs</h2></> : <></>
                        }

                        <Filters />
                        {
                            isSearchPath ?
                                <><h2>{ type === 'collections' ? "Collections" : "NFTs" }</h2></> : <></>
                        }
                    </div>
                    {
                        type == 'collections' ?
                            <Collections collections={ collections} nftList={nftList} /> : <NFTs collections={ collections} nftList={nftList} />
                    }


                </section>
            </div>
        </div>

    );
};

export default Discover;
