import React from "react";
import {APIsTree} from "../../data/APIsTree";
import {useNavigate, useParams} from "react-router-dom";

function Filters () {

    const navigate = useNavigate();
    const { site, type, keyword } = useParams();

    return (

        <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1
            }}>
                <div className="filter-options">
                    <button onClick={() => navigate(`/search/all/${typeof keyword != 'undefined' ? keyword : ''}`)}
                            className={type == null || type == 'all' ? `active` : ''}>All
                    </button>
                    <button onClick={() => navigate(`/search/nfts/${typeof keyword != 'undefined' ? keyword : ''}`)}
                            className={type == 'nfts' ? `active` : ''}>NFTs
                    </button>
                    <button
                        onClick={() => navigate(`/search/collections/${typeof keyword != 'undefined' ? keyword : ''}`)}
                        className={type == 'collections' ? `active` : ''}>Collections
                    </button>
                </div>
                <div className="filter-options">
                    <button onClick={() => navigate('/site/all')}
                            className={site == null || site == 'all' ? `active` : ''}>All
                    </button>
                    {
                        APIsTree.map((data) => (
                            <button className={site == data.slug ? `active` : ''}
                                    onClick={() => navigate('/site/' + data.slug)}> {data.name} </button>
                        ))
                    }
                </div>
            </div>
            <button className={'filter-button'}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16"
                     fill="none">
                    <path
                        d="M9.33333 16H14.6667V13.3333H9.33333V16ZM0 0V2.66667H24V0H0ZM4 9.33333H20V6.66667H4V9.33333Z"
                        fill="#371C87"/>
                </svg>
                All Filters
            </button>
        </div>
    );

}

export default Filters;