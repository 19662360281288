import Navbar from "../components/Navbar";
import Discover from "../components/Discover";

function SearchPage () {

    return (
        <>
            <Navbar />
            <Discover />
        </>
    );

}

export default SearchPage;