import "./style.css";
import creatorImage from "../../IMages/creator.png"
import Image from "../../IMages/image 1.png";


function Collection({collections, nftList}) {

    return (
        <>
            <div className="collections-grid">


                {
                    collections.map((collection) => (
                        <div className="collection-card" key={collection._id}>

                            <div className={'images'}>
                                <div className={"image"} style={{ display: 'flex', backgroundImage: "url('" + ( collection.image ? collection.image : Image ) + "')" }}></div>
                                <div className={'extra-images'}>
                                    <div className={"image"} style={{
                                        display: 'flex',
                                        backgroundImage: "url('" + (collection.image ? collection.image : Image) + "')"
                                    }}></div>
                                    <div className={"image"} style={{
                                        display: 'flex',
                                        backgroundImage: "url('" + (collection.image ? collection.image : Image) + "')"
                                    }}></div>
                                    <div className={"image"} style={{
                                        display: 'flex',
                                        backgroundImage: "url('" + (collection.image ? collection.image : Image) + "')"
                                    }}></div>
                                </div>
                            </div>

                            <div className={'details'}>
                                <h2> Amazing Collection </h2>
                                <div>
                                    <span>
                                        <img src={creatorImage} alt={``}/>
                                        by Creator name
                                    </span>
                                    <span className={'link'}> View Collection </span>
                                </div>
                            </div>

                        </div>
                    ))
                }
            </div>
        </>
    )

}

export default Collection