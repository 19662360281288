// src/services/api.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const addTagTypes = [ 'notes', 'note' ];

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery(),
    endpoints: (builder) => ({
        getCollections: builder.query({
            query: ( data ) => '/api/collections/' + data.keyword,
        }),
    }),
});

export const { useGetCollectionsQuery } = api;
