// src/features/users/usersSlice.js
import { createSlice } from '@reduxjs/toolkit';

const tabsSlice = createSlice({
    name: 'tabs',
    initialState: {
        tab: 'graph',
        tabs: [
            {
                _id: "graph",
                name: "Graph",
            }
        ]
    },
    reducers: {
        setTabs: (state, action) => action.payload,
        addTab: (state, action) => {

            state.tab = null;

            // Check if the tab with the same _id already exists
            const exists = state.tabs.find(tab => tab._id === action.payload._id);

            // Add the tab only if it doesn't exist
            if (!exists) {
                state.tabs.push(action.payload);
            }

            state.tab = action.payload._id;
        }
    },
});

export const { setTabs, addTab } = tabsSlice.actions;
export default tabsSlice.reducer;
