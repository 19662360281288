import React from 'react';
import './Navbar.css';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Logo from '../IMages/Logo.jpg'

const Navbar = () => {

    const navigate = useNavigate();

    const { keyword } = useParams();

    const [ search, setSearch ] = React.useState( keyword || '' );

    const location = useLocation();
    const isSearchPath = location.pathname.includes('search');

    return (
        <div className={'container'} style={{ backgroundColor: '#fff', borderBottom: '1px solid #EFEFEF', boxShadow: '0px 4px 4px 0px #00000040' }}>
            <div className={'row'}>
                <nav className="navbar">
                    <div className="navbar-logo">
                        <h1><Link to={'/'}><img style={{height: '45px'}} src={Logo} alt={'Logo'}/></Link></h1>
                    </div>
                    {
                        isSearchPath ?
                            <>
                                <div className="search-bar" style={{ minWidth: '451px' }}>
                                    <input value={search} type="text"
                                           placeholder="Search for collections, name, or trait"
                                           onChange={(input) => setSearch(input.target.value)}/>
                                    <button onClick={() => navigate(`/search/${search}`)}>/</button>
                                </div>
                            </> :
                            <>
                                <ul className="navbar-links">
                                    <li><a href="/">Trending</a></li>
                                    <li><a href="/">Drops</a></li>
                                    <li><a href="/">Collections</a></li>
                                    <li><a href="/">About</a></li>
                                    <li><a href="/">WhiteLabel</a></li>
                                </ul>
                            </>
                    }
                    <button className="wallet-btn">Connect Wallet</button>
                </nav>
            </div>
        </div>
    );
};

export default Navbar;
