import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Drops from "../components/Drops";
import Discover from "../components/Discover";

function LandingPage () {

    return (
        <>

            <Navbar />
            <Hero />
            <Drops />
            <Discover />
        </>
    );

}

export default LandingPage;